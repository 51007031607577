import moment from 'moment'
import 'moment/locale/de.js' // without this line it's not working

export class Formatter {
  public static formatDate(value: string, targetFormat: string = 'DD.MM.YYYY', sourceFormat: string = 'YYYY-MM-DD'): string {
    let date = moment(value, sourceFormat, true) // use strict mode for isValid() check

    if (!date.isValid()) {
      date = moment(value, 'YYYY-MM-DD hh:mm:ss', false) // use strict mode for isValid() check
    }

    let result: string = date.isValid() ? date.locale('de').format(targetFormat) : '-'

    if (result === 'Invalid date') {
      result = '-'
    }

    return result
  }

  public static formatDateTimeStr(value: string, sourceFormat: string = 'YYYY-MM-DD HH:mm:ss'): string {
    let date = moment(value, sourceFormat, true) // use strict mode for isValid() check
    let result: string = date.isValid() ? date.locale('de').format('DD.MM.YYYY HH:mm:ss') : '-'

    if (result === 'Invalid date') result = '-'

    return result
  }

  public static formatDateTime(value: Date, mode: 'none' | 'start' | 'end' = 'start'): string {
    let date = moment(value)
    let result: string = date.isValid() ? date.locale('de').format('DD.MM.YYYY HH:mm:ss') : '-'

    if (result === 'Invalid date') result = '-'

    if (mode === 'none') return result

    result = mode === 'start'
      ? date.startOf('day').locale('de').format('YYYY-MM-DDTHH:mm:ss')
      : date.endOf('day').locale('de').format('YYYY-MM-DDTHH:mm:ss')

    return result
  }

  public static formatCurrency(value: string | number): string {
    let val: number = 0

    if (typeof value === 'string') val = Number.parseFloat(value)
    else val = value

    if (isNaN(val)) val = 0

    return `${val.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €`
  }

  public static formatPercentage(value: string | number): string {
    let val: number = 0

    if (typeof value === 'string') val = Number.parseFloat(value)
    else val = value

    if (isNaN(val)) val = 0

    return `${val.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`
  }

  public static formatNumber(value: string | number, appendix: string = ''): string {
    let val: number = 0

    if (typeof value === 'string') val = Number.parseFloat(value)
    else val = value

    if (isNaN(val)) val = 0
    if (appendix !== '') appendix = ' ' + appendix

    return `${val.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` + appendix
  }

  public static formatIntNumber(value: string | number, appendix: string = ''): string {
    let val: number = 0

    if (typeof value === 'string') val = Number.parseFloat(value)
    else val = value

    if (isNaN(val)) val = 0
    if (appendix !== '') appendix = ' ' + appendix

    return `${val.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` + appendix
  }
}
